<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    ref="root"
    class="duo-gallery"
    :class="{
      'is-reversed': content.isReversed,
      'is-duo': content?.pictures?.length === 2,
    }"
  >
    <div ref="wrapper" class="duo-gallery-wrapper wrapper">
      <div class="duo-gallery__content">
        <div
          v-if="content.headline"
          class="duo-gallery__headline"
          v-html="content.headline"
        ></div>

        <h3 class="duo-gallery__content__title h3" v-html="content.title"></h3>

        <div v-if="content.tag" class="duo-gallery__content__tag mb-m">
          {{ content.tag }}
        </div>

        <HtmlText
          v-if="content.htmltext || content.text"
          class="duo-gallery__content__text"
          :content="content.htmltext || content.text"
        />

        <!-- Brands -->
        <Brands
          v-if="content.logos"
          class="duo-gallery__content__brands"
          :content="content.logos"
        ></Brands>

        <Action
          v-if="content.link"
          :content="content.link"
          class="duo-gallery__content__link"
        />

        <div v-if="content.ctas" class="duo-gallery__content__ctas">
          <PictureCta
            v-for="(cta, index) in content.ctas"
            :key="`duo-gallery__cta-${index}`"
            class="duo-gallery__content__cta"
            :content="cta"
          ></PictureCta>
        </div>

        <div
          v-if="content.individuals"
          class="duo-gallery__content__individuals"
        >
          <IndividualCta
            v-for="(cta, index) in content.individuals"
            :key="`duo-gallery__individual-${index}`"
            :content="cta"
          ></IndividualCta>
        </div>
      </div>

      <div
        v-if="content.pictures && content.pictures.length > 2"
        class="duo-gallery__media"
      >
        <PicturesBasicSlider :content="content.pictures"></PicturesBasicSlider>
      </div>

      <div v-else-if="content.pictures" class="duo-gallery__media">
        <div
          v-for="(picture, index) in content.pictures"
          :key="`duo-gallery__media-item-${index}`"
        >
          <GPicture
            class="duo-gallery__media__picture cover"
            :content="picture"
            sizes="50vw"
          />
        </div>
      </div>

      <div v-if="content.picture" class="duo-gallery__media">
        <div>
          <GPicture
            class="duo-gallery__media__picture cover"
            :content="content.picture"
            sizes="50vw"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type {
  Brand,
  Individual as IndividualType,
  Link,
  Picture,
  PictureCta as PictureCtaType,
} from '@/types'

export interface DuoGallery {
  headline?: string
  htmltext?: string
  text?: string
  tag?: string
  title: string
  pictures?: Picture[]
  picture?: Picture
  logos?: Brand[]
  logo?: Picture
  link?: Link
  ctas?: PictureCtaType[]
  individuals?: IndividualType[]
  isReversed?: boolean
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import Brands from '@/components/global/Brands.vue'
import IndividualCta from '@/components/global/IndividualCta.vue'
import PictureCta from '@/components/global/PictureCta.vue'
import PicturesBasicSlider from '@/components/slider/PicturesBasic.vue'
import { useAppear } from '@/utils/aware'

import type { PropType } from 'vue'

const root = ref(null)
const wrapper = ref()

defineProps({
  content: {
    type: Object as PropType<DuoGallery>,
    required: true,
  },
})

onMounted(() => {
  if (root.value) {
    const tl = gsap.timeline({})
    tl.set(wrapper.value, {
      opacity: 0,
    })
  }
})

useAppear(
  root,
  ({ isInViewport }) => {
    if (isInViewport) {
      const tl = gsap.timeline()

      tl.to(wrapper.value, {
        duration: 1.5,
        opacity: 1,
        ease: 'Power1.easeOut',
      })
    }
  },
  { rootMargin: '-25% 0px', once: true }
)
</script>

<style lang="scss" scoped>
.duo-gallery {
  position: relative;
  overflow: hidden;
  padding: $spacing * 2 0;

  :deep(.brands-wrapper) {
    width: 100%;
    padding-inline: 0;

    @include mq($until: m) {
      margin-top: $spacing;
    }
  }

  :deep(.brands) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    max-width: 36rem;
  }

  :deep(.brands__card) {
    max-width: none;
    padding: 16px;
    border: 1px solid $c-grey-light;
  }
}

.duo-gallery-wrapper {
  @include mq($until: m) {
    padding-inline: col(1);
  }

  @include mq(m) {
    display: flex;
    align-items: center;

    .is-reversed & {
      flex-direction: row-reverse;
    }
  }
}

.duo-gallery__media {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;

  > div {
    @extend %br-default;

    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 93/72;

    .is-duo & {
      width: 50%;
      aspect-ratio: 54/72;
    }
  }

  @include mq($until: m) {
    margin-top: 4.8rem;
  }

  @include mq(m) {
    width: col(14);
    transform: translateX(col(2, 16));

    .is-reversed & {
      transform: translateX(col(2, 16) * -1);
    }

    .is-duo & {
      width: col(16);
    }
  }
}

.duo-gallery__headline {
  @extend %fraunces-spec;

  // prettier-ignore
  @include fluid(font-size, (xxs: 3.6rem, xxl: 6rem));

  // prettier-ignore
  @include fluid(line-height, (xxs: 4.2rem, xxl: 6.8rem));

  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 4.8rem, xxl: 2.4rem));
}

.duo-gallery__content {
  @include mq(m) {
    width: col(8);
  }

  @include mq(xxxl) {
    padding-left: col(1);

    .is-reversed & {
      padding-right: col(1);
      padding-left: 0;
    }
  }
}

.duo-gallery__content__title {
  margin-top: 0;
  margin-bottom: 1.6rem;
  color: $c-green-abr;
}

.duo-gallery__content__tag {
  @extend %fw-semi;

  display: inline-flex;
  margin-top: 0.8rem;
  padding: 0.2rem 1.5rem;
  color: $c-green-abr;
  font-size: 1.6rem;
  line-height: 2.8rem;
  border: 1px solid $c-tag;
  border-radius: 0.4rem;
}

.duo-gallery__content__text {
  :deep(p) {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.duo-gallery__media__picture {
  @extend %br-default;
  @include get-all-space;
}

.duo-gallery__content__link {
  margin-top: 3.6rem;
}

.duo-gallery__content__ctas {
  margin-top: 3.6rem;
}

.duo-gallery__content__cta + .duo-gallery__content__cta {
  margin-top: 0.8rem;
}

.duo-gallery__content__individuals {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: $spacing;
  margin-top: 3.6rem;
}
</style>
