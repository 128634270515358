<template>
  <div ref="root" class="brands-wrapper wrapper">
    <div ref="brands" class="brands">
      <div
        v-for="(brand, index) in visibleItems"
        :key="`brand-${index}-${brand}`"
        class="brands__card"
      >
        <GPicture
          v-if="brand.picture"
          :key="`brand-picture-${brand}`"
          :content="brand.picture"
          class="brands__card__picture"
          sizes="12rem"
        />

        <RouterLink
          class="brands__card__link"
          :to="brand.link.url"
          :target="brand.link.target"
        >
          <span class="h-visually-hidden">
            {{ brand.picture.alt || 'Brand' }}
          </span>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Brand } from '@/types'
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onBeforeUnmount, onMounted, ref } from 'vue'

import { useAppear } from '@/utils/aware'

import type { PropType } from 'vue'

const props = defineProps({
  content: {
    type: Object as PropType<Brand[]>,
    required: true,
  },
})

const brands = ref()
const root = ref(null)
const visibleItems = ref(props.content.slice(0, 6))
const hiddenItems = ref(props.content.slice(6))
let intervalId: ReturnType<typeof setTimeout>

onMounted(() => {
  if (root.value) {
    const tl = gsap.timeline({})
    tl.set(brands.value, {
      opacity: 0,
    })

    startInterval()
  }
})

onBeforeUnmount(() => {
  clearInterval(intervalId)
})

const startInterval = () => {
  intervalId = setInterval(() => {
    const randomIndex = Math.floor(Math.random() * 6)
    const hiddenIndex = Math.floor(Math.random() * hiddenItems.value.length)

    if (hiddenIndex) {
      // console.log('visibleItems.value[randomIndex]', randomIndex, hiddenIndex)

      visibleItems.value[randomIndex] = hiddenItems.value[hiddenIndex]
      hiddenItems.value = props.content.filter(
        item1 =>
          !visibleItems.value.some(
            item2 => item1.link.title === item2.link.title
          )
      )

      // gsap.fromTo(
      //   brands.value.children[randomIndex],
      //   { opacity: 0 },
      //   { opacity: 1, duration: 3, ease: 'Power4.easeOut' }
      // )
    }
  }, 3000)
}

useAppear(
  root,
  ({ isInViewport }) => {
    if (isInViewport) {
      const tl = gsap.timeline()

      tl.to(brands.value, {
        duration: 1.5,
        opacity: 1,
        ease: 'Power1.easeOut',
      })
    }
  },
  { rootMargin: '-25% 0px', once: true }
)
</script>

<style lang="scss" scoped>
.brands-wrapper {
  // prettier-ignore
  @include fluid(padding-top,(m: .75rem,xxl: 4rem));

  // prettier-ignore
  @include fluid(padding-bottom,(m: 3.25rem,xxl: 4rem));

  position: relative;

  @include mq($until: xxxl) {
    display: inline-flex;
    overflow-x: hidden;
    width: calc(100vw - var(--scrollbar-width));
  }
}

.brands {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  gap: $spacing;

  @include mq(s) {
    gap: $spacing * 2;
  }

  @include mq(xl) {
    gap: $spacing * 4;
  }
}

.brands__card {
  position: relative;
  width: 12rem;
}

.brands__card__picture {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  aspect-ratio: 1;
}

.brands__card__link {
  @include get-all-space;
}
</style>
