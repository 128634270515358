<template>
  <div ref="root" class="pictures-bacic-slider">
    <div ref="wrapper">
      <Carousel
        ref="carousel"
        :items="content"
        :bullet-points="true"
        :prev-next="false"
      >
        <template #item="{ item }: { item: Picture }">
          <div class="js-carousel-item">
            <GPicture class="cover" :content="item" sizes="90vw" />
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script lang="ts">
import type { Picture } from '@/types'
</script>

<script setup lang="ts">
import { Carousel } from '@monofront/v-components'
import { ref } from 'vue'

import type { PropType } from 'vue'

const carousel = ref()
const wrapper = ref()

defineProps({
  content: {
    type: Object as PropType<Picture[]>,
    required: true,
  },
})

const root = ref<HTMLElement>()
</script>

<style lang="scss" scoped>
.pictures-bacic-slider {
  position: relative;
  overflow: hidden;

  :deep(.picture) {
    &.is-portrait {
      img {
        object-fit: contain;
      }
    }
  }
}

:deep(.carousel) {
  --pagination-item-gap: 0.5rem;
  --pagination-item-size: 1.2rem;
  --slide-gap: 0.8rem;
  --c-pagination-item-background-active: #{$c-green-abr};
  --c-pagination-background-inactive: rgba(0, 0, 0, 10%);
  --c-scrollbar-foreground: transparent;

  max-width: none;
}

:deep(.carousel__scroller) {
  margin-block: 0;
}

:deep(.carousel__item) {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 93/72;
}

:deep(.carousel__pagination) {
  // prettier-ignore
  @include fluid(bottom, (xxs: 1rem, xxl: 2.8rem));

  // prettier-ignore
  @include fluid(right, (xxs: 1rem, xxl: 2.8rem));

  position: absolute;
}

:deep(.carousel__pagination__button) {
  &.active {
    border: 1px solid $c-white;
  }
}
</style>
